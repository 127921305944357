import Cookies from 'cookies';
import { GetServerSidePropsContext, NextPageContext } from 'next';
import JSCookies from 'js-cookie';
import { isServer } from './platform';

export const getCookieCountry = (
  context: GetServerSidePropsContext | NextPageContext
): string => {
  const { req, res } = context;
  if (req && res) {
    const cookies = new Cookies(req, res);
    return cookies.get('prima_country') || '';
  }
  if (isServer()) return '';
  return JSCookies.get('prima_country') || '';
};

export const getSessionToken = (
  context: GetServerSidePropsContext | NextPageContext
): string => {
  const { req, res } = context;
  if (req && res) {
    const cookies = new Cookies(req, res);
    return cookies.get('prima_bearer') || '';
  }
  if (isServer()) return '';
  return JSCookies.get('prima_bearer') || '';
};

export const getCookieLogged = (
  context?: GetServerSidePropsContext | NextPageContext
): boolean => {
  const { req, res } = context || {};
  if (req && res) {
    const cookies = new Cookies(req, res);
    return cookies.get('prima_authenticated') === 'true';
  }
  if (isServer()) return false;
  return (JSCookies.get('prima_authenticated') || '') === 'true';
};

export const removeCookieAuth = (
  context: GetServerSidePropsContext | NextPageContext
): void => {
  const { req, res } = context;
  if (req && res) {
    const cookies = new Cookies(req, res);
    cookies.set('prima_authenticated', '', { path: '/', signed: false });
    return;
  }
  JSCookies.remove('prima_authenticated');
};
