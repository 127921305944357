import axios from 'axios';
import { DEFAULT_LANG, STRING_LANG } from '../helpers/languages';
import { ICountryListItem } from '../models/footer';
import data from '../public/shipping_countries_list.json';

export const getShippingRate = async ({
  country,
  lang
}: {
  country: string;
  lang: string;
}): Promise<any> => {
  const URL = `${process.env.NEXT_PUBLIC_WP_API_URL}/v1/shipping/cost`;

  try {
    const response = await axios.get(URL, {
      params: { country },
      headers: { 'UYN-LANGUAGE': lang, ['UYN-COUNTRY']: country }
    });

    return response;
  } catch (error) {
    return { error };
  }
};

export const fetchShippingCountriesList = ({
  lang = DEFAULT_LANG
}: {
  lang: STRING_LANG;
}): ICountryListItem[] => {
  return data[lang] || [];
};

export const fetchShippingCost = async (country: string): Promise<number> => {
  try {
    if (!country) throw new Error();

    const URL = `/shipping.json`;
    const { data = {} } = await axios.get(URL);
    const { free = -1 } = data[country.toUpperCase()] || {};
    return free;
  } catch (error) {
    return -1;
  }
};
